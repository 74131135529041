<template>
  <div class="admin-plugins v-step-status-code-section">
    <div class="title">
      <h1>{{ $t('admin.plugins.fivem.title') }}</h1>
    </div>
    <div class="content">
      <div class="flexbox">
        <div class="boxes">
          <ul class="col-pc-12 col-mobile-12 ul-boxes">
            <li
              v-for="plugin in $store.state.admin.plugins"
              :key="plugin.id"
              class="plugin-box col-mobile-12 col-pc-12"
            >
              <div class="li-content col-all-12">
                <div class="name col-pc-9 col-mobile-9">
                  <div class="text col-pc-12 col-mobile-12">
                    <h2>{{ plugin.name }} {{ plugin['tagline_' + $route.params.lang] ? `(${plugin['tagline_' + $route.params.lang]})` : '' }}</h2>
                  </div>
                </div>
                <div class="buttons col-pc-5 col-mobile-5">
                  <div class="download">
                    <a :href="plugin.downloadLink" target="_blank" download>
                      <i class="fas fa-download"></i>
                    </a>
                  </div>
                  <div class="information">
                    <button
                      class="primary-full"
                      @click="pluginInfoOpenned === plugin.id
                          ? (pluginInfoOpenned = '')
                          : (pluginInfoOpenned = plugin.id)"
                      :class="{ active: pluginInfoOpenned === plugin.id }"
                    >
                      <i class="far fa-question-circle"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div class="plugin-overview-dropdown col-f" v-if="pluginInfoOpenned !== plugin.id">
                <div class="row p-version">
                  <h3>{{ $t('admin.plugins.version') }}</h3>
                  <h2>{{ plugin.version }}</h2>
                </div>
                <div class="row p-size">
                  <h3>{{ $t('admin.plugins.size') }}</h3>
                  <h2>~{{ plugin.size }}</h2>
                </div>
                <div class="row p-documentation">
                  <h3>{{ $t('admin.plugins.documentation') }}</h3>
                  <h2 v-if="plugin.documentationLink">
                    <a
                      :href="plugin.documentationLink"
                      target="_blank"
                    >{{ $t('admin.plugins.visit-link') }}</a>
                  </h2>
                  <h2 v-if="!plugin.documentationLink">{{ $t('admin.plugins.not-available') }}</h2>
                </div>
                <div class="row p-licence">
                  <h3>{{ $t('admin.plugins.licence') }}</h3>
                  <h2 v-if="plugin.licenseLink">
                    <a
                      :href="plugin.licenseLink"
                      target="_blank"
                    >{{ $t('admin.plugins.visit-link') }}</a>
                  </h2>
                  <h2 v-if="!plugin.licenseLink">{{ $t('admin.plugins.not-available') }}</h2>
                </div>
              </div>
              <div
                class="plugin-informations-dropdown col-f"
                v-if="pluginInfoOpenned === plugin.id"
              >
                <div class="row p-author col-pc-fit col-mobile-fit">
                  <h3>{{ $t('admin.plugins.author') }}</h3>
                  <h2>{{ plugin.author }}</h2>
                </div>
                <div class="row p-description">
                  <h3>{{ $t('admin.plugins.description') }}</h3>
                  <h2>{{ plugin['desc_' + $route.params.lang] }}</h2>
                </div>
              </div>
            </li>
            <li class="plugin-box share-plugin col-mobile-12 col-pc-12">
              <div class="li-content col-f">
                <h2 class="sp-text">{{ $t('admin.plugins.share-plugin') }}</h2>
                <h2 class="sp-link">
                  <a
                    :href="'https://leafcad.com/' + $route.params.lang + '/support'"
                    target="_blank"
                  >
                    {{
                    $t('admin.plugins.contact-us')
                    }}
                  </a>
                </h2>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="less" scoped src="@/assets/less/dashboards/user-interfaces.less"></style>

<script>
export default {
  name: 'general',
  data() {
    return {
      pluginInfoOpenned: ''
    }
  },
  mounted() {
    this.$store.dispatch('admin/fetchPlugins')
  }
}
</script>
